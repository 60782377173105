import { signIn, useSession } from 'next-auth/react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { Text } from '@/components/text';
import { useAdvancedSearch } from '@/hooks/use-advanced-search';
import { CARGO_TYPES, MODE_AIR } from '@/lib/constants';
import { PortalContainer } from '@/lib/context/portal-container';
import { activeSearchParamsCountState, advancedSearchActivePanelState, cargoSearchParamsState } from '@/state/search';
import { AdvancedSearchCategoryItem, AdvancedSearchOptionItem } from '@/types';
import { isProductionEnv } from '@/utils/helpers';
import { getUpgradeAccountUrl } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { advancedSearchCategories } from '../config';
import {
  PanelAirCarriers,
  PanelAircraftType,
  PanelDeveloper,
  PanelLocations,
  PanelOceanCarriers,
  PanelSelection,
} from '../panels';

const IS_PRODUCTION = isProductionEnv();

const shouldShowItem = (item: AdvancedSearchOptionItem) => {
  if (item.hidden) return false;
  // Only show dev items if we are not on production
  if (item.dev && IS_PRODUCTION) return false;
  // else show all
  return true;
};

export interface AdvancedSearchFormProps {
  onSearch: () => void;
}

const AdvancedSearchForm = ({ onSearch }: AdvancedSearchFormProps) => {
  // User Sessions
  const { data: session } = useSession();

  // States
  const [activePanel, setActivePanel] = useRecoilState(advancedSearchActivePanelState);
  const activeSearchParamsCount = useRecoilValue(activeSearchParamsCountState);

  // Get cargo details to determin if certain panels should be disabled
  const cargoSelection = useRecoilValue(cargoSearchParamsState);
  const isCargoContainer = cargoSelection?.cargoType === CARGO_TYPES.CONTAINER;

  // Can user access Advanced Search
  const { hasAccess, isInTrial, expiresIn } = useAdvancedSearch();

  // Filter out any category items that should be hidden
  const visibleConfigCategories = advancedSearchCategories.reduce((accum, cat) => {
    const filteredCatItems = cat.items.filter((item) => shouldShowItem(item));

    if (filteredCatItems.length) {
      // category still has items, return all
      return [...accum, { ...cat, items: filteredCatItems }];
    }
    // skip if items are empty
    return accum;
  }, [] as AdvancedSearchCategoryItem[]);

  const handleClickSignIn = () => {
    signIn();

    trackUser.event('Go to: Sign-in', {
      location: 'Advanced Search Message',
    });
  };

  const handleClickUpgrade = () => {
    trackUser.event('Go to: Upgrade Account', {
      location: 'Advanced Search Message',
    });
  };

  const { panel } = sharedAdvancedSearchStyles();

  const renderPanels = (key: string) => {
    switch (key) {
      case 'airCarriers':
        return <PanelAirCarriers isLocked={!hasAccess} isDisabled={isCargoContainer} />;
      case 'oceanCarriers':
        return <PanelOceanCarriers isLocked={!hasAccess} />;
      case 'aircraftType':
        return <PanelAircraftType isLocked={!hasAccess} isDisabled={isCargoContainer} />;
      case 'avoid':
        return <PanelLocations avoid isLocked={!hasAccess} />;
      case 'via':
        return <PanelLocations isLocked={!hasAccess} />;
      case 'searchConfig':
        return <PanelDeveloper />;
      default:
        return <div className={panel()}>Coming soon</div>;
    }
  };

  const { base, columnGrid, column, fieldset, legend, menuItem, menuButton, buttonIndicator, lockedMessage, link } =
    styles();

  return (
    <PortalContainer>
      <div className={base()}>
        {session && !hasAccess && (
          <p className={lockedMessage()}>
            Your trial has expired and you&apos;ll need to upgrade your account to continue using Advanced Search.{' '}
            <Link className={link()} onClick={() => handleClickUpgrade()} href={getUpgradeAccountUrl()}>
              Upgrade your account
            </Link>
            .
          </p>
        )}
        {hasAccess && isInTrial && (
          <div className={lockedMessage()}>
            Your Advanced Search trial will expire{' '}
            <b>{expiresIn && expiresIn > 0 ? `in ${expiresIn} days` : `today`}</b>. After that you&apos;ll need to
            upgrade to continue using it.
          </div>
        )}
        {!session && !hasAccess && (
          <div className={lockedMessage()}>
            Try out Advanced Search features for a limited time by signing up for a free account.{' '}
            <button className={link()} type="button" onClick={() => handleClickSignIn()}>
              Sign up now
            </button>
            .
          </div>
        )}
        <form className={columnGrid()}>
          <div className={column({ className: 'pl-6' })}>
            <div>
              <Text variant="secondary" className="m-0 italic text-text-secondary">
                Select an option below to add a search parameter to your query
              </Text>
              {visibleConfigCategories.map((category) => (
                <fieldset className={fieldset()} key={category.value}>
                  <legend className={legend()}>{category.label}</legend>
                  <ul>
                    {category.items.map((item) => {
                      const categoryItemCount = activeSearchParamsCount[item.value];
                      const isActive = activePanel === item.value;
                      return (
                        <li className={menuItem()} key={item.value}>
                          <button
                            className={menuButton({ isActive })}
                            type="button"
                            disabled={isCargoContainer && item.type === MODE_AIR} // disabled air if shipping container selected
                            onClick={() => setActivePanel(item.value)}
                            aria-pressed={isActive ? 'true' : 'false'}
                          >
                            <span>{item.label}</span>
                            <Icon name="chevron-right" size="3" color="text.primary" />
                            {categoryItemCount > 0 && <span className={buttonIndicator()}>{categoryItemCount}</span>}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </fieldset>
              ))}
            </div>
          </div>
          <div className={column()}>{renderPanels(activePanel)}</div>
          <div className={column()}>
            <PanelSelection onSearch={onSearch} />
          </div>
        </form>
      </div>
    </PortalContainer>
  );
};

const styles = tv({
  slots: {
    base: 'color-grey-800 relative z-3 rounded-b-lg bg-grey-100 shadow-soft',
    columnGrid: 'grid grid-cols-[255px_1fr_1fr]',
    column:
      'relative h-full max-h-[490px] min-h-[490px] overflow-y-auto border-l border-solid border-l-grey-300 px-4 py-4 first:border-l-0',
    fieldset: 'mt-5 border-none p-0',
    legend:
      'mb-[2px] block w-full border-b border-grey-300 pb-2 text-sm font-medium uppercase leading-none text-grey-500',
    menuItem: '-mx-2',
    menuButton:
      'relative flex h-8 w-full items-center justify-between rounded-md border border-solid border-transparent px-2 text-left text-baseSm hover:border-grey-200 hover:bg-white disabled:pointer-events-none disabled:text-grey-400',
    buttonIndicator:
      'absolute right-1 top-1 ml-2 flex min-w-5 items-center justify-center rounded border border-solid border-lightBlue-700 bg-lightBlue-500 p-1 text-center text-sm font-medium leading-none text-white',
    lockedMessage: 'border-b border-states-warning-borderColor bg-states-warning-backgroundColor p-1 pl-6 text-sm',
    link: 'underline',
  },
  variants: {
    isActive: {
      true: {
        menuButton: 'border-grey-200 bg-white',
      },
    },
  },
});

export { AdvancedSearchForm };
