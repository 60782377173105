import { Container, Package, X } from 'lucide-react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Heading } from '@/components/heading';
import { TextInput } from '@/components/inputs';
import { Text } from '@/components/text';
import { Select } from '@/components/ui/inputs/select';
import {
  CARGO_TYPES,
  UNIT_DISTANCE_CM,
  UNIT_DISTANCE_IN,
  UNIT_MAXHEIGHT,
  UNIT_WEIGHT,
  UNIT_WEIGHT_KG,
  UNIT_WEIGHT_LB,
} from '@/lib/constants';
import { featuresState } from '@/state/features';
import { cargoSearchParamsState } from '@/state/search';
import { CargoOption, CargoTypes } from '@/types';
import { isCargoUnitValue } from '@/utils/helpers/type-checks';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { containerDefaults, looseItemDefaults } from '../constants';

const CargoSearchDropdownForm = () => {
  const features = useRecoilValue(featuresState);
  const [cargoParams, setCargoParams] = useRecoilState(cargoSearchParamsState);

  const SHOW_FULL_CARGO_OPTIONS = features.showFullCargoOptions;

  const isActive = (cargoType?: CargoTypes) => {
    return cargoParams?.cargoType === cargoType;
  };

  // Generic Change event where a value is provided
  const handleOnChange = (fieldName: string, value: string | number) => {
    const newCargoOptions = { ...cargoParams, [fieldName]: value };
    setCargoParams((prev) => ({ ...prev, ...newCargoOptions }));

    // Analytics Event
    trackUser.event('Cargo Filter: Param', { field: fieldName, value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name: fieldName } = e.target;
    const existingCargoParams = cargoParams || ({} as CargoOption);
    const existingUnitValue = existingCargoParams[fieldName as keyof CargoOption];

    if (isCargoUnitValue(existingUnitValue)) {
      const updatedUnitValue = { ...existingUnitValue, value: Number(value) };
      // Update value in cargo params based on input name
      const newCargoOptions = { ...existingCargoParams, [fieldName]: updatedUnitValue };
      setCargoParams((prev) => ({ ...prev, ...newCargoOptions }));
    }

    // Analytics Event
    trackUser.event('Cargo Filter: Param', { field: fieldName, value });
  };

  // Called when the Weight|Volume|Height Unit changes
  const handleUnitOnChange = (fieldName: keyof CargoOption, unit: string) => {
    const existingCargoParams = cargoParams || ({} as CargoOption);
    const existingUnitValue = existingCargoParams[fieldName];

    if (isCargoUnitValue(existingUnitValue)) {
      const updatedUnitValue = { ...existingUnitValue, unit };
      // Update value in cargo params based on input name
      const newCargoOptions = { ...existingCargoParams, [fieldName]: updatedUnitValue };
      setCargoParams((prev) => ({ ...prev, ...newCargoOptions }));
    }

    // Analytics Event
    trackUser.event('Cargo Filter: Unit', { field: fieldName, value: unit });
  };

  const handleCargoOptionChange = (option: CargoOption) => {
    // Clicking an existing selection, will remove it
    const updatedCargo = isActive(option.cargoType) ? undefined : option;
    setCargoParams(updatedCargo);

    // Analytics Event
    trackUser.event('Cargo Filter: Type', { type: option.cargoType });
  };

  const { header, headingText, cargoOptionIcon, cargoOptionButton, buttonTitle, buttonText, dismissIcon, fieldRow } =
    styles();

  return (
    <div>
      <div className={header()}>
        <Heading variant="h4" className={headingText()}>
          What are you shipping?
        </Heading>
        <Text variant="sm" className="mb-0 mt-2 leading-snug text-grey-500">
          Describe your cargo below to get tailored transport suggestions, market rates and emissions.
        </Text>
      </div>
      <form>
        <ol className="space-y-4">
          <li className="grid grid-cols-2 gap-3">
            <button
              type="button"
              className={cargoOptionButton({ isActive: isActive(CARGO_TYPES.CONTAINER) })}
              onClick={() => handleCargoOptionChange(containerDefaults)}
            >
              <X className={dismissIcon({ isActive: isActive(CARGO_TYPES.CONTAINER) })} />
              <Container className={cargoOptionIcon()} />
              <div>
                <span className={buttonTitle()}>FCL</span>
                <span className={buttonText()}>Full container load</span>
              </div>
            </button>
            <button
              type="button"
              className={cargoOptionButton({ isActive: isActive(CARGO_TYPES.ITEM) })}
              onClick={() => handleCargoOptionChange(looseItemDefaults)}
            >
              <X className={dismissIcon({ isActive: isActive(CARGO_TYPES.ITEM) })} />
              <Package className={cargoOptionIcon()} />
              <div>
                <span className={buttonTitle()}>Loose Cargo</span>
                <span className={buttonText()}>Pallets, boxes etc</span>
              </div>
            </button>
          </li>
          <li className={isActive(CARGO_TYPES.CONTAINER) ? 'block' : 'hidden'}>
            <Select
              id="containerSize"
              label="Container Size"
              placeholder="Select"
              options={[
                {
                  title: '',
                  items: [
                    { label: '20 foot', value: '20' },
                    { label: '40 foot', value: '40' },
                  ],
                },
              ]}
              selected={cargoParams?.containerSize || containerDefaults.containerSize}
              onSelect={(val) => handleOnChange('containerSize', val?.value || '')}
            />
          </li>
          {isActive(CARGO_TYPES.ITEM) && (
            <>
              <li className={fieldRow({ className: SHOW_FULL_CARGO_OPTIONS ? 'grid' : 'block' })}>
                <TextInput
                  label={`Total Weight ${SHOW_FULL_CARGO_OPTIONS ? '' : '(KGs)'}`}
                  id="weightValue"
                  type="number"
                  value={cargoParams?.weight?.value || ''}
                  name={UNIT_WEIGHT}
                  onChange={handleInputOnChange}
                />
                {SHOW_FULL_CARGO_OPTIONS && (
                  <Select
                    id="weightUnit"
                    label=""
                    placeholder="Select"
                    options={[
                      {
                        title: '',
                        items: [
                          { value: UNIT_WEIGHT_KG, label: UNIT_WEIGHT_KG },
                          { value: UNIT_WEIGHT_LB, label: UNIT_WEIGHT_LB },
                        ],
                      },
                    ]}
                    selected={cargoParams?.weight?.unit || looseItemDefaults.weight?.unit}
                    onSelect={(val) => handleUnitOnChange(UNIT_WEIGHT, val?.value)}
                  />
                )}
              </li>
              {SHOW_FULL_CARGO_OPTIONS && (
                <li className={fieldRow()}>
                  <TextInput
                    label="Tallest item height"
                    id="maxHeightValue"
                    type="number"
                    value={cargoParams?.maxHeight?.value || looseItemDefaults.maxHeight?.value}
                    name={UNIT_MAXHEIGHT}
                    onChange={handleInputOnChange}
                  />
                  <Select
                    id="maxHeightUnit"
                    label=""
                    placeholder="Select"
                    options={[
                      {
                        title: '',
                        items: [
                          { value: UNIT_DISTANCE_CM, label: UNIT_DISTANCE_CM },
                          { value: UNIT_DISTANCE_IN, label: UNIT_DISTANCE_IN },
                        ],
                      },
                    ]}
                    selected={cargoParams?.maxHeight?.unit || looseItemDefaults.maxHeight?.unit}
                    onSelect={(val) => handleUnitOnChange(UNIT_MAXHEIGHT, val?.value)}
                  />
                </li>
              )}
            </>
          )}
        </ol>
      </form>
    </div>
  );
};

const styles = tv({
  slots: {
    header: 'mb-4 border-b border-grey-200 pb-4',
    headingText: 'm-0 p-0 text-baseSm font-semibold leading-none text-grey-800',
    buttonText: 'mt-0.5 block text-sm text-grey-600',
    buttonTitle: 'block text-baseSm font-semibold tracking-[0.04rem]',
    cargoOptionButton:
      'group relative flex flex-col gap-2 rounded-lg border border-solid border-grey-300 px-3 py-2 text-left shadow-sm hover:!border-lightBlue-500 hover:outline hover:!outline-lightBlue-500',
    cargoOptionIcon: 'size-5 text-grey-800',
    dismissIcon: 'absolute right-2 top-2 hidden size-3 text-grey-500 group-hover:text-red-500',
    fieldRow: 'grid grid-cols-[1fr_6rem] items-end gap-2',
  },
  variants: {
    isActive: {
      true: {
        cargoOptionButton:
          'active border-grey-700 outline !outline-grey-700 data-[whatintent=mouse]:focus:!outline-grey-700',
        dismissIcon: '!block',
      },
    },
  },
});

export { CargoSearchDropdownForm };
