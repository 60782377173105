import { useEffect, useRef, useState } from 'react';

import { Tooltip } from '@/components/tooltip';

interface TruncatedTextProps {
  content: string;
  children: React.ReactNode;
  isOpen?: boolean;
}

const TruncatedText = ({ content, isOpen, children }: TruncatedTextProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isEllipsized, setIsEllipsized] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        const isTextEllipsized = textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsEllipsized(isTextEllipsized);
      }
    };

    checkEllipsis();
    window.addEventListener('resize', checkEllipsis);
    return () => window.removeEventListener('resize', checkEllipsis);
  }, [content]);

  // Always render the children with their original styling
  // Just wrap with tooltip if ellipsized
  return isEllipsized ? (
    <Tooltip content={content} className="max-w-80" isOpen={isOpen}>
      <span
        ref={textRef}
        style={{ display: 'inherit', overflow: 'inherit', textOverflow: 'inherit', whiteSpace: 'inherit' }}
      >
        {children}
      </span>
    </Tooltip>
  ) : (
    <span
      ref={textRef}
      style={{ display: 'inherit', overflow: 'inherit', textOverflow: 'inherit', whiteSpace: 'inherit' }}
    >
      {children}
    </span>
  );
};

export { TruncatedText };
